export const adjective = {
	singular: "investor",
	plural: "investors and advisors",
};

export const verb = {
	singular: "investing",
};

export const job = {
	singular: "deal",
	plural: "deals",
};
